import { useEffect, useState } from 'react';
import Dashboard from './dashboard';
import Statements from './statements';
import Positions from './positions';
import f from './firebase';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

function App() {
  const PAGE_DASHBOARD = 0;
  const PAGE_STATEMENT = 1;
  const PAGE_POSITIONS = 2;

  const [page, setPage] = useState(PAGE_DASHBOARD);
  const [user, setUser] = useState(null);

  const login = () => signInWithPopup(getAuth(f), new GoogleAuthProvider());

  useEffect(() => getAuth(f).onAuthStateChanged(user => {
    if (user) {
      setUser(user.displayName);
    } else {
      setUser(null);
    }
  }));

  const loggedIn = () => <div>Logged in as {user}
      &nbsp;|&nbsp;<a href="#" onClick={() => setPage(PAGE_DASHBOARD)}>Dashboard</a>
      &nbsp;|&nbsp;<a href="#" onClick={() => setPage(PAGE_STATEMENT)}>Statements</a>
    </div>;

  const notLoggedIn = () => <div>Not logged in.
      &nbsp;|&nbsp;<a href="#" onClick={login}>Login</a>
    </div>;

  return (
    <div>
      {user ? loggedIn() : notLoggedIn()}
      <hr/>
      {page === PAGE_DASHBOARD && <Dashboard />}
      {page === PAGE_STATEMENT && <Statements />}
    </div>
  ); 
}

export default App;
