import { initializeApp } from "firebase/app"

const firebase = initializeApp({
  apiKey: "AIzaSyBsHGy-mC64gIxTmTNT0PU13I9EAHMv2IY",
  authDomain: "graphene-424310.firebaseapp.com",
  projectId: "graphene-424310",
  storageBucket: "graphene-424310.appspot.com",
  messagingSenderId: "141458654486",
  appId: "1:141458654486:web:e03ae6c80ab28e879e7c37"
});

export default firebase;
