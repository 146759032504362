import { useState, useEffect } from "react";
import f from "./firebase";
import { getFirestore, collection, onSnapshot, setDoc, doc, updateDoc, addDoc } from "firebase/firestore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./customAgGrid.css";
import exchangeAu from "./data/exchange-AU.json";
import exchangeUs from "./data/exchange-US.json";
import exchangeLse from "./data/exchange-LSE.json";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

const DAY = 24*60*60*1000;
function convert(exchangeCode, data) {
  return data.map(item => {
    let id = item.Code + "." + exchangeCode;
    return {
      id: id,
      name: id + ' ' + item.Name,
      orig: item
    };
  });
}
const SEARCH_OPTIONS = convert('AU', exchangeAu).concat(convert('LSE', exchangeLse)).concat(convert('US', exchangeUs));

function prevDate(eod, date, daysago = 1) {
  let keys = Object.keys(eod).sort();
  let targetDate = new Date(date) - daysago*DAY;
  for (let i = keys.length-1; i >= 0; i--) {
    if (new Date(keys[i]) <= targetDate) {
      return keys[i];
    }
  }
  return null;
}

function calcChange(eod, date, daysago) {
  if (!date) {
    return null;
  }
  let targetDate = prevDate(eod, date, daysago);
  if (!targetDate) {
    return null;
  }
  let close = eod[date].adjusted_close;
  let closePrev = eod[targetDate].adjusted_close;
  return (close - closePrev)/closePrev*100;
}

function calcFtop(currentPrice, eod, date, daysago) {
  if (!eod || !currentPrice) {
    return null;
  }
  let targetDate = prevDate(eod, date, daysago);
  if (!targetDate) {
    return null;
  }
  let max = 0;
  for (let key in eod) {
    if (key > targetDate) {
      max = Math.max(max, eod[key].adjusted_close);
    }
  }
  if (max == 0) {
    return null;
  }
  return (currentPrice - max)/max*100;
}

function Dashboard() {
  const [ tickers, setTickers ] = useState({});
  const [ boardTickers, setBoardTickers ] = useState([]);
  const [ tickerToAdd, setTickerToAdd ] = useState({});
  const [ boards, setBoards ] = useState([]);
  const [ selectedBoard, setSelectedBoard ] = useState(null);
  const [ refreshButton, setRefreshButton ] = useState("Refresh data");

  const [ colDefs ] = useState([
    { field: "ticker", width: 180 },
    { field: "name", width: 300 },
    { field: "position", editable: true, width: 150 },
    { field: "costBasis", editable: true },
    { field: "value", aggFunc: 'sum'},
    { field: "pnl", headerName: 'P&L', aggFunc: 'sum',
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'},
      valueFormatter: params => params.value ? params.value.toFixed(2) : null,
    },
    { field: "pnlpct", headerName: 'P&L %', 
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'} },
    { field: "currentPrice", headerName: 'Current Price' },
    { field: "change0d", headerName: 'C0d',
      width: 150,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    { field: "close", headerName: "Prev" },
    // { field: "latestEodDate", headerName: 'Prev date' },
    { field: "change1d", 
      headerName: "C1d", 
      width: 150,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    { field: "change7d", 
      headerName: "C7d", 
      width: 150,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    { field: "change30d", 
      headerName: "C30d", 
      width: 150,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    { field: "change90d", 
      headerName: "C90d", 
      width: 150,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    { field: "change350d", 
      headerName: "C350d", 
      width: 150,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    {
      field: "fromTop", headerName: 'FTop',
      width: 170,
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'}
    },
    { field: "currency",     
      width: 120
    },
    { field: "type",      width: 120,
  },
    { field: "exchange", headerName: 'Exc', width: 120},
  ]);

  useEffect(() => {
    return onSnapshot(collection(getFirestore(f), 'boards'), snapshot => {
      let res = [];
      snapshot.docs.forEach(doc => {
        let data = doc.data();
        data['id'] = doc.id;
        res.push(data);
      });
      console.log(res);
      setBoards(res);
    });
  }, []);

  useEffect(() => {
    return onSnapshot(collection(getFirestore(f), 'marketdata-tickers'), snapshot => {
      let res = {};
      snapshot.docs.forEach(doc => {
        let data = doc.data();
        let close = data.latestEodDate ? data.eod[data.latestEodDate].adjusted_close : null;
        let currentPrice = data.realtimeClose ? data.realtimeClose : close;

        res[doc.id] = {          
          ticker: doc.id,
          name: data.name,
          exchange: data.exchange,
          currency: data.currency,
          type: data.type,
          refreshDaily: data.refreshDaily,
          latestEodDate: data.latestEodDate,
          close: close,
          currentPrice: currentPrice,
          change0d: close != currentPrice ? (currentPrice - close) / close * 100 : null,
          change1d: calcChange(data.eod, data.latestEodDate, 1),
          change7d: calcChange(data.eod, data.latestEodDate, 7),
          change30d: calcChange(data.eod, data.latestEodDate, 30),
          change90d: calcChange(data.eod, data.latestEodDate, 90),
          change350d: calcChange(data.eod, data.latestEodDate, 350),
          fromTop: calcFtop(currentPrice, data.eod, data.latestEodDate, 350),
        };
      });
      setTickers(res);
    });
  }, []);

  useEffect(() => {
    return onSnapshot(collection(getFirestore(f), 'boards/' + selectedBoard + '/entries'), snapshot => {
      let res = [];
      let totalValue = 0;
      snapshot.docs.forEach(doc => {
        let data = doc.data();
        let ticker = tickers[data.ticker];
        if (ticker) {
          let value = data.position && ticker.close ? data.position * ticker.close : null;
          let pnl = value && data.costBasis ? value - data.costBasis : null;
          let pnlpct = pnl ? pnl / data.costBasis * 100 : null;

          res.push({
            entryId: doc.id,
            ...data,
            ...ticker,
            value: value,
            pnl: pnl,
            pnlpct: pnlpct
          });
          totalValue += value ? value : 0;
        }
      });
      res.push({
        ticker: 'Total',
        value: totalValue
      })
      setBoardTickers(res);
    });
  }, [boards, selectedBoard, tickers]);

  const addTicker = async () => {
    if (tickerToAdd && tickerToAdd != {}) {
      let orig = tickerToAdd.orig;

      // Just clobber if it already exists
      setDoc(doc(getFirestore(f), 'marketdata-tickers', tickerToAdd.id), {
        eodhdTicker: tickerToAdd.id,
        currency: orig.Currency,
        name: orig.Name,
        country: orig.Country,
        exchange: orig.Exchange,
        type: orig.Type,
        isin: orig.Isin,
        refreshDaily: true        
      });
      // Add to current board
      if (selectedBoard) {
        addDoc(collection(getFirestore(f), 'boards/' + selectedBoard + '/entries'), {
          ticker: tickerToAdd.id,
        });
      }

      // TODO: make this work
      //setTickerToAdd({});
    }
  }

  const gridOptions = {
    autoSizeStrategy: {
      type: "fitGridWidth",
    },
    defaultColDef: {
      resizable: false,
    },
  };

  const saveCell = (event) => {
    let data = event.data;
    let id = data.entryId;
    console.log(data);
    updateDoc(doc(getFirestore(f), 'boards/' + selectedBoard + '/entries', id), {
      position: data.position ? data.position : null,
      costBasis: data.costBasis ? data.costBasis : null
    });
  };

  const openBoard = (id) => {
    return e => {
      setSelectedBoard(id);
    };
  };

  const refreshData = async () => {
    setRefreshButton("Refreshing...");
    fetch('https://asia-southeast1-graphene-424310.cloudfunctions.net/marketdata-function/blablhbalahasc32k4jrtvn3gk4vhjt3nas', { mode: 'no-cors'})
    .then((res) => {
      if (res.ok) {
        setRefreshButton("Refresh data");
      } else {
        console.log(res);
        setRefreshButton("Refresh data (retry)");
      }
    })
    .catch(err => {
      console.error(err);
      setRefreshButton("Refresh data (retry)");
    });
  };

  return (
    <div>
      <h2>Dashboard</h2>
      {boards.map(board => <div style={{display: 'inline'}}>
        <a key={board.id} style={board.id == selectedBoard ? {fontWeight: 'bold'} : {}} onClick={openBoard(board.id)} href="javascript:;">{board.title}</a>&nbsp;|&nbsp;
      </div>)}<button onClick={refreshData}>{refreshButton}</button>
      <br/><br/>
      <div className="ag-theme-quartz" style={{height: 600}} >
        <AgGridReact rowData={boardTickers} columnDefs={colDefs} gridOptions={gridOptions} onCellValueChanged={saveCell}/>
      </div>

      Add ticker: 
      <ReactSearchAutocomplete items={SEARCH_OPTIONS} onSelect={item => setTickerToAdd(item)} value={tickerToAdd}/>
      <button onClick={addTicker}>Add</button>
    </div>
  );
}

export default Dashboard;
