import { useState, useEffect } from "react";
import f from "./firebase";
import { getFirestore, collection, onSnapshot, setDoc, doc } from "firebase/firestore";
import { AgGridReac, AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import exchangeAu from "./data/exchange-AU.json";
import exchangeUs from "./data/exchange-US.json";
import exchangeLse from "./data/exchange-LSE.json";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

const DAY = 24*60*60*1000;
function convert(exchangeCode, data) {
  return data.map(item => {
    let id = item.Code + "." + exchangeCode;
    return {
      id: id,
      name: id + ' ' + item.Name,
      orig: item
    };
  });
}
const SEARCH_OPTIONS = convert('AU', exchangeAu).concat(convert('LSE', exchangeLse)).concat(convert('US', exchangeUs));

function prevDate(eod, date, daysago = 1) {
  let keys = Object.keys(eod).sort();
  let targetDate = new Date(date) - daysago*DAY;
  for (let i = keys.length-1; i >= 0; i--) {
    if (new Date(keys[i]) < targetDate) {
      return keys[i];
    }
  }
  return null;
}

function calcChange(eod, date, daysago) {
  if (!date) {
    return null;
  }
  let targetDate = prevDate(eod, date, daysago);
  let close = eod[date].adjusted_close;
  let closePrev = eod[targetDate].adjusted_close;
  return (close - closePrev)/closePrev*100;
}

function Positions() {
  const [ tickers, setTickers ] = useState([]);
  const [ tickerToAdd, setTickerToAdd ] = useState({});
  const [ colDefs ] = useState([
    { field: "ticker" },
    { field: "name"},
    { field: "countryExchange", headerName: 'Country/Exchange'},
    { field: "currency" },
    { field: "type" },
    { field: "close", headerName: "Prev close" },
    { field: "openedDate" },
    { field: "closedDate" },
    { field: "costBasis"},
    { field: "closedAmount"},
    { field: "position" },
    { field: "value" },
    { field: "pnl", headerName: 'Unrealized P&L', cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'} },
    { field: "pnlpct", headerName: 'Unrealized P&L %', 
      valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
      cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'} },
    { field: "pnl", headerName: 'Realized P&L', cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'} },
    { field: "pnlpct", headerName: 'Realized P&L %', 
        valueFormatter: params => params.value ? params.value.toFixed(2) + '%' : null,
        cellStyle: params => params.value > 0 ? {color: 'green'} : {color: 'red'} }
  ]);

  useEffect(() => {
    return onSnapshot(collection(getFirestore(f), 'marketdata-tickers'), snapshot => {
      let res = [];
      snapshot.docs.forEach(doc => {
        let data = doc.data();
        let position = 100;
        let costBasis = 10000;
        let close = data.latestEodDate ? data.eod[data.latestEodDate].adjusted_close : null;
        let value = position && close ? position * close : null;
        let pnl = value ? value - costBasis : null;
    
        res.push({          
          ticker: doc.id,
          name: data.name,
          countryExchange: data.country + '/' + data.exchange,
          currency: data.currency,
          type: data.type,
          refreshDaily: data.refreshDaily,
          latestEodDate: data.latestEodDate,
          close: close,
          change1d: calcChange(data.eod, data.latestEodDate, 1),
          change7d: calcChange(data.eod, data.latestEodDate, 7),
          change30d: calcChange(data.eod, data.latestEodDate, 30),
          change90d: calcChange(data.eod, data.latestEodDate, 90),
          change350d: calcChange(data.eod, data.latestEodDate, 350),
          position: position,
          value: value,
          pnl: pnl,
          pnlpct: pnl ? pnl / costBasis * 100 : null
        });
      });
      setTickers(res);
    });
  }, []);

  const addTicker = () => {
    if (tickerToAdd && tickerToAdd != {}) {
      setTickerToAdd({});
      let orig = tickerToAdd.orig;
      setDoc(doc(getFirestore(f), 'marketdata-tickers', tickerToAdd.id), {
        eodhdTicker: tickerToAdd.id,
        currency: orig.Currency,
        name: orig.Name,
        country: orig.Country,
        exchange: orig.Exchange,
        type: orig.Type,
        isin: orig.Isin,
        refreshDaily: true        
      });
    }
  }

  const gridOptions = {
    autoSizeStrategy: {
      type: "fitGridWidth",
    }
  };

  return (
    <div>
      <h2>Positions</h2>
      <div className="ag-theme-quartz" style={{height: 600}} >
        <AgGridReact rowData={tickers} columnDefs={colDefs} gridOptions={gridOptions}/>
      </div>

      Add ticker: 
      <ReactSearchAutocomplete items={SEARCH_OPTIONS} onSelect={item => setTickerToAdd(item)} />
      <button onClick={addTicker}>Add</button>
    </div>
  );
}

export default Positions;
